@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  background-color: rgb(241 245 249);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

form .amplify-flex .amplify-button.amplify-field-group__control.amplify-button--primary {
  background: #d12728;
}

form .amplify-flex .amplify-button.amplify-field-group__control.amplify-button--primary:hover {
  background: #9c1d1e;
}

.amplify-flex.amplify-tabs button.amplify-tabs-item[data-state='active'] {
  color: #d12728;
  border-color: #d12728;
}

div.Mui-TableHeadCell-Content-Wrapper.MuiBox-root {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: rgb(107, 114, 128);
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5rem;
}
