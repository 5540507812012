#signout-button {
  background-color: #d12728;
  color: #ffffff;
  font-size: small;
}

#signout-button:hover {
  background-color: #b61c1f;
}

#menu-button {
  background-color: #ffffff;
}

#menu-button:hover {
  background-color: #f5f5f5;
}

.menu-link:hover {
  background-color: #f5f5f5;
}

.menu-selected {
  background-color: #f5f5f5;
}

@media (max-width: 767px) {
  .menu {
    transition: height 0.35s ease-in-out, opacity 0.35s ease-in-out;
    overflow: hidden;
    height: 0;
    opacity: 0;
  }

  .menu-hidden {
    height: 0;
    opacity: 0;
  }

  .menu-show {
    height: auto;
    opacity: 1;
  }
}
